import { RootReducerState } from '../../redux/reducers'

export const openDrawerFiltersSelector = (state: RootReducerState) => state.ui.openDrawerFilters
export const openDrawerMegaMenuSelector = (state: RootReducerState) => state.ui.openDrawerMegaMenu
export const openDrawerSearchSelector = (state: RootReducerState) => state.ui.openDrawerSearch
export const openDrawerNewsLetterSelector = (state: RootReducerState) => state.ui.openDrawerNewsLetter
export const openDrawerCartSummarySelector = (state: RootReducerState) => state.ui.openDrawerCartSummary
export const openDrawerProductNotificationSelector = (state: RootReducerState) =>
  state.ui.openDrawerProductNotificationAvailable
export const hamburgerMenuOpenSelector = (state: RootReducerState) => state.ui.hamburgerMenuDrawerOpen
export const lastSelectedProductSelector = (state: RootReducerState) => state.ui.lastSelectedProduct
export const productsMobileListingLayoutSelector = (state: RootReducerState) => state.ui.productsMobileListingLayout
export const openModalSignInSelector = (state: RootReducerState) => state.ui.openModalSignIn
export const openModalRegistrationSelector = (state: RootReducerState) => state.ui.openModalRegistration
export const showFullPageLoaderSelector = (state: RootReducerState) => state.ui.showFullPageLoader
export const removingBasketItemSelector = (state: RootReducerState): string | null => state?.ui?.isRemovingBasketItem
export const basketBusySelector = (state: RootReducerState): boolean => !!state?.ui?.isBasketBusy
export const isPHIPopupVisibleSelector = (state: RootReducerState) => state.ui.isPHIPopupVisible
export const totalPaginationPagesSelector = (state: RootReducerState): number => state.ui.totalPaginationPages
