import { isValidElement } from 'react'
import { StyledPriceWrapper, StyledRrpPrice } from './ProductTilePrice.style'
import { ProductPriceReturn } from '../ProductPrice/Price'
import { Variant } from '@mui/material/styles/createTypography'
import { Skeleton } from '@mui/material'

type ProductTilePriceProps<T extends ProductPriceReturn> = {
  rrp?: React.ReactNode
  offerPrice?: React.ReactNode
  variant?: Variant
  loading?: boolean
}

function ProductTilePrice<T extends ProductPriceReturn>({
  rrp,
  offerPrice,
  variant = 'h5',
  loading,
}: ProductTilePriceProps<T>): JSX.Element {
  const isOnOffer = !!(offerPrice && offerPrice)
  const isValidRrp = isValidElement(rrp) && rrp.props.price > 0

  return (
    <StyledPriceWrapper variant={variant}>
      {loading ? (
        <Skeleton width={100} />
      ) : (
        <>
          {isValidRrp && <StyledRrpPrice isOnOffer={isOnOffer}>{rrp}</StyledRrpPrice>}
          {isOnOffer && offerPrice}
        </>
      )}
    </StyledPriceWrapper>
  )
}

export default ProductTilePrice
