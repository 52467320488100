import { CircleChevronLeftIcon, CircleChevronRightIcon } from '@components/UI/Icons/chevron'
import clsx from 'clsx'
import NukaCarousel, { CarouselProps as NukaCarouselProps, ScrollMode, ControlProps } from 'nuka-carousel'
import React, { useCallback, CSSProperties } from 'react'
import styles from './styles/index.module.scss'
import { CarouselProps } from './types'
import { useTranslation } from 'next-i18next'

const defaultCarouselProps: NukaCarouselProps = {
  speed: 500,
  dragThreshold: 0.2,
  slidesToScroll: 1,
  slidesToShow: 1,
  scrollMode: ScrollMode.remainder,
  dragging: false,
  autoplay: false,
  renderBottomCenterControls: (props: ControlProps) => <PagingDotsStyled {...props} />,
}

const getBoundedIndex = (rawIndex: number, slideCount: number) => {
  return ((rawIndex % slideCount) + slideCount) % slideCount
}

const PagingDotsStyled = ({
  pagingDotsIndices,
  defaultControlsConfig: { pagingDotsContainerClassName, pagingDotsClassName, pagingDotsStyle = {}, pagingDotsOnClick },
  carouselId,
  currentSlide,
  onUserNavigation,
  slideCount,
  goToSlide,
  tabbed,
}: ControlProps) => {
  const listStyles: CSSProperties = {
    position: 'relative',
    display: 'flex',
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  }

  const getButtonStyles = useCallback(
    (active: boolean) => ({
      cursor: 'pointer',
      opacity: active ? 1 : 0.2,
      background: 'transparent',
      border: 'none',
      fill: 'black',
    }),
    []
  )
  const currentSlideBounded = getBoundedIndex(currentSlide, slideCount)

  if (!tabbed || pagingDotsContainerClassName == 'hidden') return null

  return (
    <div
      className={pagingDotsContainerClassName}
      style={listStyles}
      role="tablist"
      aria-label="Choose slide to display."
    >
      {pagingDotsIndices.map((slideIndex, i) => {
        const isActive =
          currentSlideBounded === slideIndex ||
          // sets navigation dots active if the current slide falls in the current index range
          (currentSlideBounded < slideIndex && (i === 0 || currentSlideBounded > pagingDotsIndices[i - 1]))

        return (
          <button
            key={slideIndex}
            className={['paging-item', pagingDotsClassName, isActive ? 'active' : null].join(' ')}
            type="button"
            style={{
              ...getButtonStyles(isActive),
              ...pagingDotsStyle,
            }}
            onClick={event => {
              pagingDotsOnClick?.(event)
              if (event.defaultPrevented) return

              onUserNavigation(event)

              goToSlide(slideIndex)
            }}
            aria-label={`slide ${slideIndex + 1}`}
            aria-selected={isActive}
            aria-controls={`${carouselId}-slide-${slideIndex + 1}`}
            role="tab"
          >
            {isActive ? (
              <svg className="paging-dot" width="8" height="8" aria-hidden="true" focusable="false" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="4" fill="#0137EF" />
              </svg>
            ) : (
              <svg className="paging-dot" width="8" height="8" aria-hidden="true" focusable="false" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="4" fill="#000000" />
              </svg>
            )}
          </button>
        )
      })}
    </div>
  )
}

export const Carousel: React.FC<Omit<NukaCarouselProps, 'innerRef'> & CarouselProps> = ({
  children,
  animationTime = 5500,
  loop,
  withoutBullets = true,
  withoutButtons = true,
  ...props
}) => {
  const {
    defaultControlsConfig,
    innerRef,
    slidesPerView,
    centeredSlides,
    spaceBetween,
    slidesPerGroup,
    renderDefaultArrows,
    ...carouselProps
  } = props
  const { t } = useTranslation()

  return (
    <NukaCarousel
      renderAnnounceSlideMessage={() => ''}
      renderCenterLeftControls={({ previousSlide }) =>
        renderDefaultArrows && (
          <button className={clsx(styles['arrow-button'])} onClick={previousSlide} aria-label={t('Carousel.prev')}>
            <CircleChevronLeftIcon />
          </button>
        )
      }
      renderCenterRightControls={({ nextSlide }) =>
        renderDefaultArrows && (
          <button className={clsx(styles['arrow-button'])} onClick={nextSlide} aria-label={t('Carousel.next')}>
            <CircleChevronRightIcon />
          </button>
        )
      }
      ref={innerRef}
      autoplayInterval={animationTime}
      wrapAround={loop}
      withoutControls={withoutBullets && withoutButtons}
      defaultControlsConfig={{
        prevButtonClassName: withoutButtons ? 'hidden' : '',
        nextButtonClassName: withoutButtons ? 'hidden' : '',
        pagingDotsContainerClassName: withoutBullets ? 'hidden' : '',
        ...(defaultControlsConfig ? { ...defaultControlsConfig } : {}),
      }}
      {...defaultCarouselProps}
      {...carouselProps}
      slidesToShow={slidesPerView}
      cellAlign={centeredSlides ? 'center' : 'left'}
      cellSpacing={spaceBetween}
      slidesToScroll={slidesPerGroup || 1}
    >
      {children}
    </NukaCarousel>
  )
}
