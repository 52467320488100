import { CmsPicture } from '@components/common/Media/CmsPicture'
import { IVideoMedia, TMedia } from '@typesApp/cmsPlacement/Media'
import { isVideoMedia } from '@typesApp/teaser'
import clsx from 'clsx'
import { VideoPlayer } from '../VideoPlayer'
import { getMediaPictureUris, getPictureSources } from './helpers'
import { ImageCrops } from './types'
import { hostnameUrlsSelector } from '@redux/selectors/site'
import { useSelector } from 'react-redux'

type CmsMedia = {
  imageCrops: ImageCrops
  media: TMedia[] | undefined
  hasOverlay?: boolean
  onSetPlayingId?: (id?: string) => void
  playingId?: string
  canVideoBeClickable?: boolean
  lazy?: boolean
  alt?: string
}

export const CmsMedia: React.FC<CmsMedia> = ({
  imageCrops,
  media,
  hasOverlay,
  onSetPlayingId,
  playingId,
  canVideoBeClickable,
  lazy,
  alt,
}) => {
  const hostnameUrls = useSelector(hostnameUrlsSelector)
  const isVideo = isVideoMedia(media?.[0])
  const mediaUris = getMediaPictureUris(media ?? [])
  const sources = getPictureSources(hostnameUrls, imageCrops, mediaUris)
  const altText = media?.[0]?.alt || alt

  if (!media) return null

  return (
    <div
      className={clsx('media-wrapper', {
        'has-overlay': hasOverlay,
        'is-video': isVideo,
      })}
    >
      {isVideo && (
        <VideoPlayer
          lazyload={false}
          shouldLoad={true}
          media={media as IVideoMedia[]}
          onSetPlayingId={onSetPlayingId}
          playingId={playingId}
          canVideoBeClickable={canVideoBeClickable}
          isInView={!lazy}
        />
      )}
      {sources.length > 0 && (
        <CmsPicture
          sources={sources}
          wrapper={{
            condition: true,
            wrapperTag: 'div',
          }}
          placeholder={{
            src: sources[sources.length - 1].srcSet,
            width: imageCrops.xxl.width,
            height: imageCrops.xxl.height,
            fetchPriority: !lazy ? 'high' : 'auto',
            preload: !lazy,
            alt: altText,
          }}
          lazy={lazy}
        />
      )}
    </div>
  )
}
