import { Typography, styled } from '@mui/material'

export const StyledProductLabel = styled('div', {
  name: 'ProductLabel',
})(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  display: 'inline-block',
  position: 'absolute',
  padding: theme.spacing(1, 4),
  width: 'fit-content',
  left: 'auto',
  right: '0',
  bottom: '20px',
  zIndex: '1',
  borderTopLeftRadius: '6px',
  borderBottomLeftRadius: '6px',

  [theme.breakpoints.up('sm')]: {
    bottom: '32px',
  },
  [theme.breakpoints.up('md')]: {
    bottom: '22px',
  },

  '&.CL_BADGE_MULTIFOCAL': {
    backgroundColor: theme.palette.warning.main,
  },
  '&.CL_BADGE_ASTIGMATISM': {
    backgroundColor: theme.palette.success.main,
  },
}))

export const StyledProductLabelText = styled(Typography, {
  name: 'ProductLabelText',
})(({ theme }) => ({
  display: 'block',
  color: theme.palette.common.white,
  fontWeight: 600,
}))
