import { IVideoMedia } from '@typesApp/cmsPlacement/Media'
import qs from 'qs'
import queryString from 'query-string'
import config from '../configs'
import { cmsImageCrops } from '../constants/ui'
import { STORE_URL_MAP } from '@constants/common'
import apiUrls from '@configuration/config.json'
import { replaceLocaleInUrl } from './locale'

export const formatPathWithBase = (path: string, basePath: string): string => {
  return basePath + (path.startsWith('/') ? path : `/${path}`)
}

/**
 * Update URL query string values
 * @returns {string}
 */
export const updateUrlParameter = (
  url: string,
  key: string,
  value: number | boolean | string | undefined | null
): string => {
  const [baseurl, search] = url.split('?')
  const querystring = qs.parse(search)
  querystring[key] = value == null ? '' : value.toString()

  return `${baseurl}?${qs.stringify(querystring)}`
}

export const replaceUrl = url => {
  window.history.replaceState({}, '', url)
}

export const formattedParams = params => {
  return queryString.stringifyUrl(
    {
      url: '',
      query: params,
    },
    {
      encode: false,
    }
  )
}

export const fromUrlParamsToObject = (url: string, decode = false) => {
  return queryString.parse(url, { decode })
}

export const getImageFromCMS = (
  hostnameUrls: Record<string, string>,
  url: string,
  crop?: string,
  width?: number | string
) => {
  const imageHostname = hostnameUrls.cmsImageServerUrl || config.cmsImageServerUrl

  if (!url) {
    return ''
  }
  if (crop) {
    const rep = url.replace('/{cropName}/{width}', `/${crop || 'NOTFOUND'}/${width || 'NOTFOUND'}`)
    return `${imageHostname}${rep}`
  }
  return undefined
}

export const getCmsImageCrops = (type: string) => {
  const bannerCropOption = cmsImageCrops[type]
  const { mobile, deskL, deskS, tabletL, tabletP } = bannerCropOption || {}
  return {
    mobile,
    deskL,
    deskS,
    tabletL,
    tabletP,
  }
}

export const getSrcSetsImageFromCms = (type: string, url: string, hostnameUrls: Record<string, string>) => {
  const { mobile, deskL, deskS, tabletL, tabletP } = getCmsImageCrops(type)

  return {
    mobile: getImageFromCMS(hostnameUrls, url, mobile?.crop || '', mobile?.width),
    deskL: getImageFromCMS(hostnameUrls, url, deskL?.crop || '', deskL?.width),
    deskS: getImageFromCMS(hostnameUrls, url, deskS?.crop || '', deskS?.width),
    tabletL: getImageFromCMS(hostnameUrls, url, tabletL?.crop || '', tabletL?.width),
    tabletP: getImageFromCMS(hostnameUrls, url, tabletP?.crop || '', tabletP?.width),
  }
}

export const getVideoFromCMS = (video: IVideoMedia): string => {
  const url = video.data?.uri || video.dataUrl
  const uri = url.startsWith('/') ? url : `/${url}`
  return `${config.cmsImageServerUrl}${uri}`
}

/**
 * removeStoreSegment remove the store segment of the url
 * retrieved from CMS
 * @param  url the url to parse
 *
 */
export const removeStoreSegment = url => {
  return url
    .split('/')
    .filter(item => item.length > 0)
    .join('/')
    .replace('//', '/')
}

export const seoUrlConcat = (langCode: string, seoHref: string, absoluteUrl = true) => {
  let url = !langCode.startsWith('/') && absoluteUrl ? `/${langCode}` : langCode
  let divider = '/'

  if (url && url.endsWith('/')) {
    url = url.slice(0, -1)
  }

  if (seoHref && seoHref.startsWith('/')) {
    divider = ''
  }

  return [url, seoHref].join(divider)
}

export const getDomainFromHost = (hostname: string): string => {
  const parts = hostname.split('.')
  if (parts.length <= 2) {
    return hostname
  }
  return parts
    .slice(-3)
    .join('.')
    .match(/\.co\.uk$/)
    ? parts.slice(-3).join('.')
    : parts.slice(-2).join('.')
}

export const replaceSEODomain = (hrefLang: string, hostname: string): string => {
  const currentDomain =
    process.env.NODE_ENV === 'development'
      ? 'https://www.visiondirect.it'
      : apiUrls[hostname]?.publicUrl ?? 'https://www.visiondirect.it'
  const domainRegex = /visiondirect(.+)$/
  const replacement = STORE_URL_MAP[hrefLang] ?? '.it'

  return currentDomain.replace(domainRegex, `visiondirect${replacement}`)
}

//! temporary work around , will be removed once we have the correct url from the backend
export const createRedirectUrl = (
  locale: string,
  isLocaleDomain: boolean,
  locationOrigin: string,
  path: string | undefined,
  fragment?: string
) => {
  if (!path) {
    return ''
  }
  const parts = path.split('/')
  parts[1] = locale
  let newPath = parts.join('/')

  if (fragment) {
    newPath = `${newPath}#${fragment}`
  }

  if (!isLocaleDomain) {
    newPath = `${locationOrigin}${newPath}`
  }

  return replaceLocaleInUrl({
    href: newPath,
    locale,
    isLocaleDomain,
    locationOrigin,
    isRelative: false,
  })
}
